import React from "react";
import Layout from "../../components/layout";
import ProjectArticle from "../../components/projectArticle";
import ProjectImage from "../../components/projectImage";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const DigitalTherapy = (props) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: {eq: "images/therapy/therapy-hero.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      team: file(relativePath: {eq: "images/therapy/team-composition.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      changes: file(relativePath: {eq: "images/therapy/app-changes.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      flow: file(relativePath: {eq: "images/therapy/ux-flow.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      workshop: file(relativePath: {eq: "images/therapy/workshop.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      }
    }
  `)

  return (
    <Layout theme='light'>
      <div className='flex flex-col md:grid grid-cols-desktop project md:max-h-hero -mt-6 md:mb-12 h-auto'>
      <div className='col-start-2 col-span-5'>
          <h1 className='text-6xl md:text-8xl lg:text-8xl my-8'>Designing for Digital Therapies</h1>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>ROLE:</span> Design Lead &amp; Interaction Designer</p>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>INDUSTRY:</span> Healthcare</p>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>DATE:</span> 2019</p>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>NOTE:</span> The UI in the project has been updated to obfuscate the client's identity.</p>
      </div>
        <GatsbyImage
          alt={'An example screen from the project'}
          image={getImage(data.hero)}
          objectPosition='top'
          className={'md:-mr-36 2xl:mr-0 shadow-lg col-start-8 col-span-8 object-cover object-top h-80 md:h-full md:max-h-hero rounded-lg order-first md:order-last'}
          imgClassName={`rounded-lg`}
        />
      </div>
      <ProjectArticle>
        <section>
          <h2>Context</h2>
          <p>A major pharmaceutical company hired IA Collaborative to design a first-of-its-kind digital prescription that required a lengthy FDA approval process. We joined the project after another design firm had done significant work on the product.</p>
          <p>As the project matured, I shifted from acting as an individual contributor to leading the design team made up of two other interaction designers, two graphic designers, and a content strategist. Our team:</p>
          <ul>
            <li>Performed UX audits on existing design work.</li>
            <li>Changed the UX direction for the flagship product based on the needs of our target users&#8212;people new to managing aspects of their health with an app&#8212;so that it would be a more directed experience.</li>
            <li>Developed a design system that was flexible enough to use across a suite of products, fit within their brand guidelines, and felt less clinical than other products in their portfolio.</li>
          </ul>
        </section>
        <section>
          <h2>Working as a Player-Coach</h2>
          <p>At the beginning of the project, I worked alongside another interaction designer to identify UX pitfalls in the inherited design and make improvements ahead of a round of human factors testing.</p>
          <p>Based on our work, the client expanded our scope. The team grew, and my responsibilities grew along with it. I took on the role of player-coach&#8212;managing the day-to-day relationship with client stakeholders, coordinating timelines across work streams, and coaching less experienced designers in product design fundamentals.</p>
        </section>
        <ProjectImage
          imageObject={data.team}
          altText='An org chart'
          caption='Over the course of the project, my role shifted from being an individual contributor (left) to leading the design team (right) and managing day-to-day project operations.'
          imageClass={'shadow-md'}
        />
        <section>
          <p>The other designers did not have as much experience with complex digital products, so in the first months of the project we focused our design reviews on establishing good product design practices.</p>
          <p>We covered topics like:</p>
          <ul>
            <li>Establishing product design principles to guide our work</li>
            <li>Designing for accessibility and can taking advantage of iOS's accessibility features</li>
            <li>And working alongside developers</li>
          </ul>
        </section>
        <section>
          <h2>Principle Guided Design</h2>
          <p>Working with our client partners, we established product design principles based on the target users. Our principles included:</p>
          <ul>
            <li><span className='font-sans-medium'>Make It Accessible</span> - People living with diabetes often suffer from visual impairments.</li>
            <li><span className='font-sans-medium'>Give Each Screen a Purpose</span> - We were targeting users who did not have experience managing their disease with an app.</li>
            <li><span className='font-sans-medium'>Explain What's Happening</span> - Build trust by providing helper text and explaining what will happen after they take action.</li>
            <li><span className='font-sans-medium'>Let the User Take Control</span> - Ultimately, the users know their bodies the best. Let them make the final decision about what they need to do.</li>
          </ul>
          <p>The principles helped our team align on decisions more quickly, communicate more concisely, and&#8212;perhaps most significantly&#8212;gave our client partners a framework for providing constructive feedback, moving beyond personal likes and dislikes.</p>
        </section>
        <ProjectImage
        imageObject={data.changes}
        altText='An example of the before and after of the designs'
        imageClass={'shadow-md'}
        caption='An example of our product design principles applied. The design we inherited (left) looked approachable but confused users. Our design (right) focused each screen on a discrete task, exposed input options, and added helper content to reassure novice users.'
        />
        <section>
          <h2>Communicating Design to Client Leaders</h2>
          <p>Perhaps the project's biggest challenge was communicating design decisions to a team of stakeholders who were experts in their fields but unfamiliar with design. We ran in-person workshops every other month that focused on making big product decisions, reporting out research, and co-creation activities for establishing personas and user journeys.</p>
          <p>We invited our stakeholders to join us for remote design reviews every other week. The reviews helped familiarize them with the design process and expand their design vocabulary by discussing work before it was complete. We created a casual environment to discuss design tradeoffs, dive into the reasoning behind an opinion without feeling confrontational, and leave space for the team to try alternate solutions rather than designing by committee.</p>
        </section>
        <ProjectImage
          imageObject={data.flow}
          altText='An example of a UX flow document'
          imageClass={'shadow-md'}
          caption='Product decisions often got derailed by discussions around details on individual screens. We switched from using polished prototypes to black and white user flows so that our clients could get a complete picture of a feature, digest UX reasoning asynchronously, and avoid distractions from in-progress brand work.'
        />
        <ProjectImage
          imageObject={data.workshop}
          altText='A photo of a design workshop'
          imageClass={'shadow-md'}
          caption='We ran in-person workshops every other month that focused on making big product decisions, reporting out research, and co-creation activities for establishing personas and user journeys.'
        />
        <section>
          <h2>Outcomes</h2>
          <p>Preparing to submit a product for FDA approval is a lengthy process. I left IA Collaborative before the client submitted the product for review. The quality of work and client trust I helped establish led to several contract extensions and a long-term relationship.</p>
        </section>
      </ProjectArticle>
    </Layout>
  );
};

export default DigitalTherapy;
